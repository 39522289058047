//Without partner
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { IsLoggedIn } from "../../session";
import { Padding, IsArrayNull, FormatDate } from "../../helper";
import MenuItem from "../../common/menuitem";
import Loader from "../../common/loader";
import FiltersCompoment2 from "./FiltersCompoment2";
import { GetMerchantsWithout } from "../../services/reportApi";

import "./merchantReport.css";

const MerchantReport = () => {
  const [rslt, setResult] = useState(null);
  const [progress, setProgress] = useState(false);
  const [moreFilters, setmoreFilters] = useState(false);
  const [orgresult, setOrgResult] = useState(null);
  const [filters, setfilters] = useState({
    startDate: null,
    endDate: null,
    createdon: null,
    count: "",
    merchantname: "",
    ordernum: "",
    senderid: "",
  });

  useEffect(() => {
    if (IsLoggedIn("userToken")) {
      getDefaultResults();
    }
  }, []);

  const getDefaultResults = async () => {
    setProgress(true);
    const rslt = await GetMerchantsWithout();
    if (rslt) setResult(rslt.data);
    if (rslt) setOrgResult(rslt.data);

    setProgress(false);
  };
  const handlefilters = (data, filters) => {
    setfilters(filters);
    setResult(data);
  };
  const ItemRow = ({ number, item }) => {
    const numberCount = number + 1;
    return (
      <>
        <tr>
          <td>{Padding(numberCount, 5)}</td>
          <td>{item.senderid}</td>
          <td>{item.merchantname}</td>
          <td>{item.ordernum}</td>
          <td>{item.count}</td>
          <td>{FormatDate(item.createdon)}</td>
        </tr>
      </>
    );
  };

  return (
    <>
      {!IsLoggedIn("userToken") ? (
        <Redirect to="/" />
      ) : (
        <>
          <Loader show={progress} />
          <div className="leftMenu">
            <div className="menuColumn">
              <MenuItem />
            </div>
            <div className="workColumn">
              <main>
                <div className="row">
                  <div className="navlocleft">Merchant without partners</div>
                  <div className="navlocright">
                    <button
                      className="button"
                      onClick={() => setmoreFilters(!moreFilters)}
                    >
                      Filters
                    </button>
                  </div>
                </div>
                <div>
                  {moreFilters && (
                    <FiltersCompoment2
                      filters={filters}
                      handlefilters={handlefilters}
                      orgresult={orgresult}
                    />
                  )}
                </div>
                <table id="merchantreport">
                  <tbody>
                    <tr>
                      <th>SI. No</th>
                      <th>MerchantId</th>
                      <th>Merchant</th>
                      <th>Order Number</th>
                      <th>SMS Count</th>
                      <th>Created On</th>
                    </tr>
                    {!IsArrayNull(rslt) ? (
                      rslt.map((item, index) => (
                        <ItemRow key={index} number={index} item={item} />
                      ))
                    ) : (
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan="10">
                          No Details found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </main>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MerchantReport;
