import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { FormatDate } from "../../helper";

import "react-datepicker/dist/react-datepicker.css";
import "./Filters.css";

function FiltersCompoment({ filters, handlefilters, orgresult }) {
  const [state, setstate] = useState({
    companyname: filters.companyname,
    sendertype: filters.sendertype,
    startDate: filters.startDate,
    endDate: filters.endDate,
    createdon: filters.created,
    orderstatusid: filters.orderstatusid,
    agentname: filters.agentname,
    mobile: filters.mobile,
    consignee: filters.consignee,
    consigneenum: filters.consigneenum,
    consignor: filters.consignor,
    consignornum: filters.consignornum,
  });

  const statusTypes = [
    { statusid: 0, statusname: "Default" },
    { statusid: 1, statusname: "Ready To Pickup" },
    { statusid: 2, statusname: "Picked Up" },
    { statusid: 3, statusname: "In-Transit" },
    { statusid: 4, statusname: "Delivered" },
    { statusid: 5, statusname: "Cancelled" },
  ];

  const handleFilter = (e) => {
    setstate({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const filterData = () => {
    let data = orgresult;
    let filters = state;

    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key] !== "") {
        switch (key) {
          case "createdon":
            data = data.filter(
              (item) => FormatDate(item.createdon) === FormatDate(filters[key])
            );
            break;
          case "startDate":
            data = data.filter((item) => {
              return FormatDate(item.createdon) >= FormatDate(filters[key]);
            });
            break;
          case "endDate":
            data = data.filter((item) => {
              return FormatDate(item.createdon) <= FormatDate(filters[key]);
            });
            break;
          case "sendertype":
            let filternumber = "partner".includes(filters[key].toLowerCase())
              ? 3
              : 2;
            data = data.filter((item) => {
              return item.sendertype === filternumber;
            });
            break;
          case "orderstatusid":
            const selectedValue = parseInt(filters[key]);

            if (selectedValue === 0) {
            } else {
              data = data.filter(
                (item) => item.orderstatusid === selectedValue
              );
            }
            break;
          default:
            data = data.filter((item) =>
              item[key].toLowerCase().includes(filters[key].toLowerCase())
            );
        }
      }
    });
    handlefilters(data, state);
  };

  return (
    <div className="main">
      <div className="buttonsrow">
        <div className="buttons">
          <button
            className="button"
            onClick={() =>
              setstate({
                companyname: "",
                sendertype: "",
                startDate: null,
                endDate: null,
                createdon: null,
                orderstatusid: 0,
                agentname: "",
                mobile: "",
                consignee: "",
                consigneenum: "",
                consignor: "",
                consignornum: "",
              })
            }
          >
            Clear All
          </button>
        </div>
        <div className="buttons2">
          <button className="button" onClick={filterData}>
            Apply
          </button>
        </div>
      </div>

      <div className="filterrow">
        <div className="col-3">
          <label htmlFor="companyname">Company</label>
          <input
            type="text"
            name="companyname"
            value={state.companyname}
            onChange={(event) => handleFilter(event)}
          />
        </div>
        <div className="col-3">
          <label htmlFor="sendertype">Type</label>
          <input
            type="text"
            name="sendertype"
            value={state.sendertype}
            onChange={(event) => handleFilter(event)}
          />
        </div>
        <div className="col-3">
          <label htmlFor="orderstatusid">Status Type </label>
          <select
            name="orderstatusid"
            onChange={(event) => handleFilter(event)}
            value={state.orderstatusid}
            noValidate
          >
            {statusTypes &&
              statusTypes.map((mitem, index) => (
                <option key={index} value={mitem.statusid}>
                  {mitem.statusname}
                </option>
              ))}
          </select>
        </div>
        <div className="col-3">
          <label htmlFor="created">Created On</label>
          <DatePicker
            selected={state.createdon}
            dateFormat="yyyy-MMM-dd"
            isClearable
            onChange={(date) => {
              setstate({ ...state, createdon: date });
            }}
          />
        </div>
      </div>
      <div className="filterrow">
        <div className="col-3">
          <label htmlFor="consignee">Consignee</label>
          <input
            type="text"
            name="consignee"
            value={state.consignee}
            onChange={(event) => handleFilter(event)}
          />
        </div>
        <div className="col-3">
          <label htmlFor="consigneenum">consigneenum</label>
          <input
            type="text"
            name="consigneenum"
            value={state.consigneenum}
            onChange={(event) => handleFilter(event)}
          />
        </div>
        <div className="col-3">
          <label htmlFor="consignor">consignor</label>
          <input
            type="text"
            name="consignor"
            value={state.consignor}
            onChange={(event) => handleFilter(event)}
          />
        </div>
        <div className="col-3">
          <label htmlFor="consignornum">consignornum</label>
          <input
            type="text"
            name="consignornum"
            value={state.consignornum}
            onChange={(event) => handleFilter(event)}
          />
        </div>
      </div>
      <div className="filterrow">
        <div className="col-3">
          <label htmlFor="agentname">agentname</label>
          <input
            type="text"
            name="agentname"
            value={state.agentname}
            onChange={(event) => handleFilter(event)}
          />
        </div>
        <div className="col-3">
          <label htmlFor="mobile">Agent mobile</label>
          <input
            type="text"
            name="mobile"
            value={state.mobile}
            onChange={(event) => handleFilter(event)}
          />
        </div>
        <div className="col-3">
          <label htmlFor="startDate">From</label>
          <DatePicker
            selected={state.startDate}
            dateFormat="yyyy-MMM-dd"
            isClearable
            onChange={(date) => setstate({ ...state, startDate: date })}
          />
        </div>
        <div className="col-3">
          <label htmlFor="endDate">To</label>
          <DatePicker
            selected={state.endDate}
            dateFormat="yyyy-MMM-dd"
            isClearable
            onChange={(date) => setstate({ ...state, endDate: date })}
          />
        </div>
      </div>
    </div>
  );
}

export default FiltersCompoment;
