import API from "./api";
import { Retrieve } from "../session";

const GetHeader = async () => {
  const token = await Retrieve("userToken");
  return {
    "Content-Type": "application/json",
    Authorization: `bearer ${token}`,
  };
};

const GetPartners = async () => {
  let token = await GetHeader();
  try {
    const res = await API.get("partnerreport", {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};
const GetPartnerDetails = async (id) => {
  let token = await GetHeader();
  try {
    const res = await API.get(`partnerreport/${id}`, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};
const GetMerchantsWith = async () => {
  let token = await GetHeader();
  try {
    const res = await API.get("merchantreportwith", {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};
const GetMerchantsWithout = async () => {
  let token = await GetHeader();
  try {
    const res = await API.get("merchantreportwithout", {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};
export {
  GetPartners,
  GetMerchantsWith,
  GetMerchantsWithout,
  GetPartnerDetails,
};
