//With partner
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { IsLoggedIn } from "../../session";
import { Padding, IsArrayNull, FormatDate } from "../../helper";
import MenuItem from "../../common/menuitem";
import Loader from "../../common/loader";
import FiltersCompoment from "./FiltersCompoment";
import { GetMerchantsWith, GetPartnerDetails } from "../../services/reportApi";

import "./merchantReport.css";

const MerchantReport = () => {
  const [rslt, setResult] = useState(null);
  const [view, setView] = useState(false);
  const [selecteditem, setSelectedItem] = useState(null);
  const [partneritem, setPartnerItem] = useState(null);
  const [progress, setProgress] = useState(false);
  const [moreFilters, setmoreFilters] = useState(false);
  const [orgresult, setOrgResult] = useState(null);
  const [filters, setfilters] = useState({
    startDate: null,
    endDate: null,
    createdon: null,
    count: "",
    merchantname: "",
    partnername: "",
    ordernum: "",
    merchantid: "",
    senderid: "",
  });

  useEffect(() => {
    if (IsLoggedIn("userToken")) {
      if (selecteditem) {
        getPartnerDetails(selecteditem.senderid);
      }
    }
  }, [selecteditem]);

  useEffect(() => {
    if (IsLoggedIn("userToken")) {
      getDefaultResults();
    }
  }, []);

  const getDefaultResults = async () => {
    setProgress(true);
    const rslt = await GetMerchantsWith();

    if (rslt) setResult(rslt.data);
    if (rslt) setOrgResult(rslt.data);
    setProgress(false);
  };

  const getPartnerDetails = async (id) => {
    const rslt = await GetPartnerDetails(id);
    if (rslt) setPartnerItem(rslt.data);
  };
  //With partner
  const ItemRow = ({ number, item }) => {
    const numberCount = number + 1;
    return (
      <>
        <tr>
          <td>{Padding(numberCount, 5)}</td>
          <td>{item.merchantid}</td>
          <td>{item.merchantname}</td>
          <td>{item.senderid}</td>
          <td
            className="linkitem"
            onClick={() => {
              setView(true);
              setSelectedItem(item);
            }}
          >
            {item.partnername}
          </td>
          <td>{item.ordernum}</td>
          <td>{item.count}</td>
          <td>{FormatDate(item.createdon)}</td>
        </tr>
      </>
    );
  };
  //Without partner

  const ItemRow3 = ({ number, item }) => {
    return (
      <>
        <tr>
          <td>{item.merchantname}</td>
          <td>{item.count}</td>
        </tr>
      </>
    );
  };

  const OnCancelClicked = (e) => {
    e.preventDefault();
    setSelectedItem(null);
    setView(false);
  };

  const handlefilters = (data, filters) => {
    setfilters(filters);
    setResult(data);
  };

  // Modal for partner detail
  const PartnerModal = () => {
    return (
      <>
        <div className={"modal display-block"}>
          <section className="modal-dialog">
            <div className="modal-dialog-header">Partner details</div>
            <div className="modal-dialog-body">
              <div className="modal-popupbox">
                <div className="model-input-container">
                  {!IsArrayNull(partneritem) ? (
                    <div
                      style={{ fontWeight: "bold", fontSize: 20, marginTop: 0 }}
                    >
                      {partneritem[0].partnername}
                    </div>
                  ) : (
                    <></>
                  )}
                  <table id="modal">
                    <tbody>
                      <tr>
                        <th>Merchant</th>
                        <th>No of orders</th>
                      </tr>
                      {!IsArrayNull(partneritem) ? (
                        partneritem.map((item, index) => (
                          <ItemRow3 key={index} number={index} item={item} />
                        ))
                      ) : (
                        <tr>
                          <td style={{ textAlign: "center" }} colSpan="10">
                            No Details found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="modal-dialog-footer">
                <button
                  className="modal-dialog-cancel"
                  onClick={(event) => OnCancelClicked(event)}
                >
                  Back
                </button>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  };

  return (
    <>
      {!IsLoggedIn("userToken") ? (
        <Redirect to="/" />
      ) : (
        <>
          <Loader show={progress} />
          <div className="leftMenu">
            <div className="menuColumn">
              <MenuItem />
            </div>
            <div className="workColumn">
              <main>
                <div className="row">
                  <div className="navlocleft">Merchant with partners</div>
                  <div className="navlocright">
                    <button
                      className="button"
                      onClick={() => setmoreFilters(!moreFilters)}
                    >
                      Filters
                    </button>
                  </div>
                </div>
                <div>
                  {moreFilters && (
                    <FiltersCompoment
                      filters={filters}
                      handlefilters={handlefilters}
                      orgresult={orgresult}
                    />
                  )}
                </div>
                <table id="merchantreport">
                  <tbody>
                    <tr>
                      <th>Sl.No</th>
                      <th>Merchant Id</th>
                      <th>Merchant</th>
                      <th>Partner Id</th>
                      <th>Partner</th>
                      <th>Order Number</th>
                      <th>SMS Count</th>
                      <th>Created On</th>
                    </tr>
                    {!IsArrayNull(rslt) ? (
                      rslt.map((item, index) => (
                        <ItemRow key={index} number={index} item={item} />
                      ))
                    ) : (
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan="10">
                          No Details found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {view && <PartnerModal />}
              </main>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MerchantReport;
