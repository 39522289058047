import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { FormatDate } from "../../helper";

import "react-datepicker/dist/react-datepicker.css";
import "../order/Filters.css";

function FiltersCompoment2({ filters, handlefilters, orgresult }) {
  const [state, setstate] = useState({
    startDate: filters.startDate,
    endDate: filters.endDate,
    createdon: filters.createdon,
    count: filters.count,
    merchantname: filters.merchantname,
    ordernum: filters.ordernum,
    senderid: filters.senderid,
  });

  const handleFilter = (e) => {
    setstate({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const filterData = () => {
    let data = orgresult;
    let filters = state;
    console.log(data[0]);
    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key] !== "") {
        switch (key) {
          case "createdon":
            data = data.filter(
              (item) => FormatDate(item.createdon) === FormatDate(filters[key])
            );
            break;
          case "startDate":
            data = data.filter((item) => {
              return FormatDate(item.createdon) >= FormatDate(filters[key]);
            });
            break;
          case "endDate":
            data = data.filter((item) => {
              return FormatDate(item.createdon) <= FormatDate(filters[key]);
            });
            break;
          default:
            data = data.filter((item) =>
              item[key]
                .toString()
                .toLowerCase()
                .includes(filters[key].toString().toLowerCase())
            );
        }
      }
    });
    handlefilters(data, state);
  };

  return (
    <div className="main">
      <div className="buttonsrow">
        <div className="buttons">
          <button
            className="button"
            onClick={() =>
              setstate({
                startDate: null,
                endDate: null,
                createdon: null,
                count: "",
                merchantname: "",
                ordernum: "",
                senderid: "",
              })
            }
          >
            Clear All
          </button>
        </div>
        <div className="buttons2">
          <button className="button" onClick={filterData}>
            Apply
          </button>
        </div>
      </div>

      <div className="filterrow">
        <div className="col-3">
          <label htmlFor="merchantname">Merchant</label>
          <input
            type="text"
            name="merchantname"
            value={state.merchantname}
            onChange={(event) => handleFilter(event)}
          />
        </div>
        <div className="col-3">
          <label htmlFor="senderid">Merchant id</label>
          <input
            type="text"
            name="senderid"
            value={state.senderid}
            onChange={(event) => handleFilter(event)}
          />
        </div>
        <div className="col-3"></div>
      </div>

      <div className="filterrow">
        <div className="col-3">
          <label htmlFor="ordernum">Order No</label>
          <input
            type="text"
            name="ordernum"
            value={state.ordernum}
            onChange={(event) => handleFilter(event)}
          />
        </div>
        <div className="col-3">
          <label htmlFor="count">SMS Count</label>
          <input
            type="text"
            name="count"
            value={state.count}
            onChange={(event) => handleFilter(event)}
          />
        </div>
        <div className="col-3"></div>
      </div>
      <div className="filterrow">
        <div className="col-3">
          <label htmlFor="created">Created On</label>
          <DatePicker
            selected={state.createdon}
            dateFormat="yyyy-MMM-dd"
            isClearable
            onChange={(date) => {
              setstate({ ...state, createdon: date });
            }}
          />
        </div>
        <div className="col-3">
          <label htmlFor="startDate">From</label>
          <DatePicker
            selected={state.startDate}
            dateFormat="yyyy-MMM-dd"
            isClearable
            onChange={(date) => setstate({ ...state, startDate: date })}
          />
        </div>
        <div className="col-3">
          <label htmlFor="endDate">To</label>
          <DatePicker
            selected={state.endDate}
            dateFormat="yyyy-MMM-dd"
            isClearable
            onChange={(date) => setstate({ ...state, endDate: date })}
          />
        </div>
      </div>
    </div>
  );
}

export default FiltersCompoment2;
