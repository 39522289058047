import React, { useState, useEffect } from "react";
import "./boxcompanymap.css";
import { IsNumber, InsertSelect, IsArrayNull } from "../../helper";

import { Get as GetBoxes } from "../../services/boxApi";
import { GetSelect as GetMerchants } from "../../services/merchantApi";
import { GetSelect as GetPartners } from "../../services/partnerApi";
import { Add } from "../../services/mapcompanyApi";
import { IsLoggedIn } from "../../session";

const AddItem = ({ show, popupHandler }) => {
  const [dialog, setDialog] = useState(show);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [item, setItem] = useState({
    boxid: 0,
    companyid: 0,
    companytype: 0,
    statusid: 1,
  });

  const [boxes, setBoxes] = useState([]);

  const [partners, setPartners] = useState([]);
  const [merchants, setMerchants] = useState([]);

  const getDefaultItems = async () => {
    let rslt1 = await GetBoxes();
    let defaSelect = { boxid: 0, boxname: "Select" };
    rslt1 = InsertSelect(rslt1, defaSelect);

    setBoxes(rslt1);
    let rslt3 = await GetMerchants();
    defaSelect = { merchantid: 0, merchantname: "Select" };
    rslt3 = InsertSelect(rslt3, defaSelect);

    setMerchants(rslt3);

    let rslt2 = await GetPartners();
    defaSelect = { partnerid: 0, partnername: "Select" };
    rslt2 = InsertSelect(rslt2, defaSelect);

    setPartners(rslt2);
  };

  const [typeId, setType] = useState({
    companytype: 2,
  });

  useEffect(() => {
    setDialog(show);
    setItem({
      boxid: 0,
      companyid: 0,
      companytype: 0,
      statusid: 1,
    });
    if (IsLoggedIn("userToken")) {
      getDefaultItems();
    }
  }, [show, setItem]);

  const OnCancelClicked = (e) => {
    e.preventDefault();
    setSuccess("");
    setError("");
    setItem({
      boxid: 0,
      companyid: 0,
      companytype: 0,
      statusid: 1,
    });
    popupHandler("Cancel");
  };

  const OnCompanyTypeChanged = (event) => {
    setType({
      companytype: parseInt(event.target.value),
    });
  };

  const OnSubmitClicked = async (e) => {
    e.preventDefault();
    setSuccess("");
    setError("");
    if (!IsNumber(item.boxid)) {
      setError("Secure Box is not valid");
      return;
    }

    if (!IsNumber(item.companyid)) {
      setError("CompanyId is not valid");
      return;
    }

    if (!IsNumber(item.companytype)) {
      setError("Company Type is not valid");
      return;
    }
    item.companytype = typeId.companytype;

    const rslt = await Add(item);
    if (rslt.status === 100) {
      popupHandler("Success");
    } else {
      setError(rslt.statusText);
    }
  };
  const handleChange = (e) => {
    e.preventDefault();
    setSuccess("");
    setError("");
    let target = e.target;
    setItem({
      ...item,
      [target.name]: target.value,
    });
  };

  return (
    <div className={dialog ? "modal display-block" : "modal display-none"}>
      <section className="modal-dialog">
        <div className="modal-dialog-header">Map Box and Controller</div>
        <div className="modal-dialog-body">
          <div className="modal-popupbox">
            <table id="agentinput">
              <tbody>
                <tr>
                  <td>
                    <label htmlFor="boxid">Secure Box</label>
                  </td>
                  <td>
                    <select
                      name="boxid"
                      onChange={(event) => handleChange(event)}
                      value={item.boxid}
                      noValidate
                    >
                      {boxes &&
                        boxes.map((mitem, index) => (
                          <option key={mitem.boxid} value={mitem.boxid}>
                            {mitem.boxname}
                          </option>
                        ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Company</td>
                  <td>
                    <input
                      type="radio"
                      id="merchanttype"
                      name="type"
                      value={2}
                      checked={typeId.companytype === 2}
                      onChange={OnCompanyTypeChanged}
                    />
                    <label htmlFor="merchanttype">Merchants</label>
                    <input
                      type="radio"
                      id="partnertype"
                      name="type"
                      value={3}
                      checked={typeId.companytype === 3}
                      onChange={OnCompanyTypeChanged}
                    />
                    <label htmlFor="partnertype">Partners</label>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  {typeId.companytype === 2 ? (
                    <td>
                      <select
                        style={{ width: "100%" }}
                        name="companyid"
                        onChange={(event) => handleChange(event)}
                        value={item.companyid}
                        noValidate
                      >
                        {!IsArrayNull(merchants) &&
                          merchants.map((item, index) => (
                            <option key={index} value={item.merchantid}>
                              {item.merchantname}
                            </option>
                          ))}
                      </select>
                    </td>
                  ) : (
                    <td>
                      <select
                        style={{ width: "100%" }}
                        name="companyid"
                        onChange={(event) => handleChange(event)}
                        value={item.companyid}
                        noValidate
                      >
                        {!IsArrayNull(partners) &&
                          partners.map((item, index) => (
                            <option key={index} value={item.partnerid}>
                              {item.partnername}
                            </option>
                          ))}
                      </select>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>

            {error && <div className="model-input-error">{error}</div>}
            {success && <div className="model-input-success">{success}</div>}
          </div>
        </div>

        <div className="modal-dialog-footer">
          <button
            className="modal-dialog-cancel"
            onClick={(event) => OnCancelClicked(event)}
          >
            Cancel
          </button>
          <button
            className="modal-dialog-submit"
            onClick={(event) => OnSubmitClicked(event)}
          >
            Submit
          </button>
        </div>
      </section>
    </div>
  );
};

export default AddItem;
