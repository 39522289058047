import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { IsLoggedIn } from "../../session";
import MenuItem from "../../common/menuitem";
import { Padding, IsArrayNull, FormatDate } from "../../helper";
import Loader from "../../common/loader";
import { GetPartners } from "../../services/reportApi";

import "./partnerReport.css";

const PartnerReport = () => {
  const [rslt, setResult] = useState(null);
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    if (IsLoggedIn("userToken")) {
      getDefaultResults();
    }
  }, []);

  const getDefaultResults = async () => {
    setProgress(true);
    const rslt = await GetPartners();
    if (rslt) setResult(rslt.data);
    setProgress(false);
  };

  const ItemRow = ({ number, item }) => {
    const numberCount = number + 1;
    return (
      <>
        <tr>
          <td>{Padding(numberCount, 5)}</td>
          <td>{item.senderid}</td>
          <td>{item.partnername}</td>
          <td>{item.merchantid}</td>
          <td>{item.merchantname}</td>
          <td>{item.ordernum}</td>
          <td>{item.smscount}</td>
          <td>{FormatDate(item.createdon)}</td>
        </tr>
      </>
    );
  };

  return (
    <>
      {!IsLoggedIn("userToken") ? (
        <Redirect to="/" />
      ) : (
        <>
          <Loader show={progress} />
          <div className="leftMenu">
            <div className="menuColumn">
              <MenuItem />
            </div>
            <div className="workColumn">
              <div className="row">
                <div className="navlocleft">Partner Report</div>
              </div>
              <main>
                <table id="partnerreport">
                  <tbody>
                    <tr>
                      <th>SI. No</th>
                      <th>Partnerid</th>
                      <th>Partner</th>
                      <th>Merchant Id</th>
                      <th>Merchant</th>
                      <th>Order Number</th>
                      <th>SMS Count</th>
                      <th>Created On</th>
                    </tr>
                    {!IsArrayNull(rslt) ? (
                      rslt.map((item, index) => (
                        <ItemRow key={index} number={index} item={item} />
                      ))
                    ) : (
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan="10">
                          No Details found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </main>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PartnerReport;
